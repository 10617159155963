import styled from "styled-components";
import profileDavid from "../media/profile-david.jpg";
import profileEmilio from "../media/profile-emilio.jpg";
import profilePauli from "../media/profile-pauli.jpg";
import profileInca from "../media/profile-inca.jpg";
import { translations } from "../Translations";
import { useContext } from "react";
import { StateContext } from "../App.js";

const PageContainer = styled.div`
  min-height: 100vh;
  display: grid;
  @media only screen and (min-width: 1360px) {
    grid-template-columns: auto auto auto auto;
  }

  @media only screen and (min-width: 960px) and (max-width: 1360px) {
    grid-template-columns: auto auto;
  }

  @media only screen and (max-width: 959px) {
    grid-template-rows: auto auto auto auto;
  }
`;
const ProfileCardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .divider {
    height: 2px;
    width: 100%;
    background-color: black;
  }
  a {
    color: black;
  }
  /* :nth-child(even) {
        background-color: black;   
        color: white; 
        .divider {
            background-color: white;
        }
        a {
            color: white;
        }
    } */
`;
const ProfileCard = styled.div`
  margin: 32px 0;
  h2 {
    width: 256px;
  }
  p {
    width: 256px;
  }
  transition: 0.5s;
`;
const ProfileImage = styled.img`
  display: block;
  width: 256px;
  height: 256px;
  object-fit: cover;
  /* -webkit-mask-image: url(${(props) => props.blobMask}); */
  border-radius: 100%;
`;
const blobMasks = [
  "data:image/svg+xml;base64,PCEtLT94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/LS0+CiAgICAgICAgICAgICAgPHN2ZyBpZD0ic3ctanMtYmxvYi1zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSI+CiAgICAgICAgICAgICAgICAgICAgPGRlZnM+IAogICAgICAgICAgICAgICAgICAgICAgICA8bGluZWFyR3JhZGllbnQgaWQ9InN3LWdyYWRpZW50IiB4MT0iMCIgeDI9IjEiIHkxPSIxIiB5Mj0iMCI+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8c3RvcCBpZD0ic3RvcDEiIHN0b3AtY29sb3I9InJnYmEoMjQ4LCAxMTcsIDU1LCAxKSIgb2Zmc2V0PSIwJSI+PC9zdG9wPgogICAgICAgICAgICAgICAgICAgICAgICAgICAgPHN0b3AgaWQ9InN0b3AyIiBzdG9wLWNvbG9yPSJyZ2JhKDI1MSwgMTY4LCAzMSwgMSkiIG9mZnNldD0iMTAwJSI+PC9zdG9wPgogICAgICAgICAgICAgICAgICAgICAgICA8L2xpbmVhckdyYWRpZW50PgogICAgICAgICAgICAgICAgICAgIDwvZGVmcz4KICAgICAgICAgICAgICAgIDxwYXRoIGZpbGw9InVybCgjc3ctZ3JhZGllbnQpIiBkPSJNMTguOCwtMjMuOUMyNC4zLC0xNy44LDI4LjYsLTExLjgsMzAuNSwtNC44QzMyLjQsMi4xLDMxLjgsMTAuMSwyOSwxOC41QzI2LjIsMjYuOSwyMS4yLDM1LjgsMTMuOCwzOC44QzYuNCw0MS45LC0zLjQsMzkuMSwtMTIuMiwzNS4yQy0yMSwzMS4yLC0yOC43LDI2LjEsLTMyLjksMTguOUMtMzcuMSwxMS44LC0zNy43LDIuNSwtMzYuOSwtNy4zQy0zNi4xLC0xNywtMzMuOCwtMjcuMywtMjcuNCwtMzMuM0MtMjEsLTM5LjMsLTEwLjUsLTQxLC0xLjksLTM4LjdDNi42LC0zNi41LDEzLjMsLTMwLjEsMTguOCwtMjMuOVoiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUwIDUwKSIgc3Ryb2tlLXdpZHRoPSIwIiBzdHlsZT0idHJhbnNpdGlvbjogYWxsIDAuM3MgZWFzZSAwczsiPjwvcGF0aD4KICAgICAgICAgICAgICA8L3N2Zz4=",
  "data:image/svg+xml;base64,PCEtLT94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/LS0+CiAgICAgICAgICAgICAgPHN2ZyBpZD0ic3ctanMtYmxvYi1zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSI+CiAgICAgICAgICAgICAgICAgICAgPGRlZnM+IAogICAgICAgICAgICAgICAgICAgICAgICA8bGluZWFyR3JhZGllbnQgaWQ9InN3LWdyYWRpZW50IiB4MT0iMCIgeDI9IjEiIHkxPSIxIiB5Mj0iMCI+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8c3RvcCBpZD0ic3RvcDEiIHN0b3AtY29sb3I9InJnYmEoMjQ4LCAxMTcsIDU1LCAxKSIgb2Zmc2V0PSIwJSI+PC9zdG9wPgogICAgICAgICAgICAgICAgICAgICAgICAgICAgPHN0b3AgaWQ9InN0b3AyIiBzdG9wLWNvbG9yPSJyZ2JhKDI1MSwgMTY4LCAzMSwgMSkiIG9mZnNldD0iMTAwJSI+PC9zdG9wPgogICAgICAgICAgICAgICAgICAgICAgICA8L2xpbmVhckdyYWRpZW50PgogICAgICAgICAgICAgICAgICAgIDwvZGVmcz4KICAgICAgICAgICAgICAgIDxwYXRoIGZpbGw9InVybCgjc3ctZ3JhZGllbnQpIiBkPSJNMjMuOSwtMzAuOUMyOS4yLC0yNCwzMC42LC0xNC44LDMxLjYsLTYuMUMzMi41LDIuNiwzMi45LDEwLjksMjkuNiwxNy42QzI2LjQsMjQuMiwxOS40LDI5LjIsMTEuNSwzMi4zQzMuNiwzNS41LC01LjIsMzcsLTEzLjcsMzVDLTIyLjMsMzMuMSwtMzAuNywyNy44LC0zNi41LDIwQy00Mi4zLDEyLjEsLTQ1LjYsMS44LC00Mi4yLC01LjdDLTM4LjksLTEzLjIsLTI4LjgsLTE3LjgsLTIwLjcsLTI0LjJDLTEyLjUsLTMwLjYsLTYuMywtMzguOSwxLjUsLTQwLjdDOS4zLC00Mi41LDE4LjUsLTM3LjgsMjMuOSwtMzAuOVoiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUwIDUwKSIgc3Ryb2tlLXdpZHRoPSIwIiBzdHlsZT0idHJhbnNpdGlvbjogYWxsIDAuM3MgZWFzZSAwczsiPjwvcGF0aD4KICAgICAgICAgICAgICA8L3N2Zz4=",
  "data:image/svg+xml;base64,PCEtLT94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/LS0+CiAgICAgICAgICAgICAgPHN2ZyBpZD0ic3ctanMtYmxvYi1zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSI+CiAgICAgICAgICAgICAgICAgICAgPGRlZnM+IAogICAgICAgICAgICAgICAgICAgICAgICA8bGluZWFyR3JhZGllbnQgaWQ9InN3LWdyYWRpZW50IiB4MT0iMCIgeDI9IjEiIHkxPSIxIiB5Mj0iMCI+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8c3RvcCBpZD0ic3RvcDEiIHN0b3AtY29sb3I9InJnYmEoMjQ4LCAxMTcsIDU1LCAxKSIgb2Zmc2V0PSIwJSI+PC9zdG9wPgogICAgICAgICAgICAgICAgICAgICAgICAgICAgPHN0b3AgaWQ9InN0b3AyIiBzdG9wLWNvbG9yPSJyZ2JhKDI1MSwgMTY4LCAzMSwgMSkiIG9mZnNldD0iMTAwJSI+PC9zdG9wPgogICAgICAgICAgICAgICAgICAgICAgICA8L2xpbmVhckdyYWRpZW50PgogICAgICAgICAgICAgICAgICAgIDwvZGVmcz4KICAgICAgICAgICAgICAgIDxwYXRoIGZpbGw9InVybCgjc3ctZ3JhZGllbnQpIiBkPSJNMTkuMywtMjUuMkMyNS4zLC0xOCwzMC40LC0xMi4yLDMyLjksLTQuOEMzNS4zLDIuNSwzNSwxMS4zLDMxLDE3LjhDMjcuMSwyNC4yLDE5LjUsMjguNCwxMS42LDMxQzMuNiwzMy42LC00LjYsMzQuNSwtMTEuMSwzMS42Qy0xNy41LDI4LjgsLTIyLjIsMjIuMSwtMjcuMiwxNUMtMzIuMSw3LjgsLTM3LjQsMC4yLC0zOC4xLC04LjZDLTM4LjgsLTE3LjMsLTM0LjgsLTI3LC0yNy43LC0zMy45Qy0yMC42LC00MC44LC0xMC4zLC00NC45LC0xLjgsLTQyLjhDNi43LC00MC43LDEzLjQsLTMyLjMsMTkuMywtMjUuMloiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUwIDUwKSIgc3Ryb2tlLXdpZHRoPSIwIiBzdHlsZT0idHJhbnNpdGlvbjogYWxsIDAuM3MgZWFzZSAwczsiPjwvcGF0aD4KICAgICAgICAgICAgICA8L3N2Zz4=",
];

function Team() {
  const { language } = useContext(StateContext);

  return (
    <div className="Hero">
      <PageContainer>
        <ProfileCardContainer>
          <ProfileCard>
            <ProfileImage
              src={profileDavid}
              blobMask={blobMasks[0]}
            ></ProfileImage>
            <h2>David Anghel</h2>
            <p>{translations[language].roles.david}</p>
            <div className="divider"></div>
            <p> {translations[language].bios.david}</p>
          </ProfileCard>
        </ProfileCardContainer>
        <ProfileCardContainer>
          <ProfileCard>
            <ProfileImage
              src={profileEmilio}
              blobMask={blobMasks[1]}
            ></ProfileImage>
            <h2>Emilio Janhunen Calderón</h2>
            <p>{translations[language].roles.emilio}</p>

            <div className="divider"></div>
            <p>
              {" "}
              {translations[language].bios.emilio}
              <br />
              <br />
              <a
                href="mailto:emilio@depfilms.com"
                className="underlineAnimateReverse"
              >
                emilio@depfilms.com
              </a>
            </p>
          </ProfileCard>
        </ProfileCardContainer>
        <ProfileCardContainer>
          <ProfileCard>
            <ProfileImage
              src={profilePauli}
              blobMask={blobMasks[2]}
            ></ProfileImage>
            <h2>Pauli Janhunen Calderón</h2>
            <p>{translations[language].roles.pauli}</p>
            <div className="divider"></div>
            <p> {translations[language].bios.pauli}</p>
          </ProfileCard>
        </ProfileCardContainer>
        <ProfileCardContainer>
          <ProfileCard>
            <ProfileImage
              src={profileInca}
              blobMask={blobMasks[0]}
            ></ProfileImage>
            <h2>Inca Whyte</h2>
            <p>{translations[language].roles.inca}</p>
            <div className="divider"></div>
            <p> {translations[language].bios.inca} </p>
          </ProfileCard>
        </ProfileCardContainer>
      </PageContainer>
    </div>
  );
}

export default Team;
